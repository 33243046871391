import { cloneDeep } from 'lodash';

// 位置默认信息
export const getPositionStyle = () => ({
  head: {
    fontSize: 12,
    height: 44,
    left: 20,
    top: 20,
    width: 163
  },
  title: {
    fontSize: 30,
    fontSize_lg: 30,
    fontSize_sm: 26,
    fontSize_xs: 22,
    height: 120,
    left: 20,
    top: 133,
    width: 330
  },
  time: {
    height: 36,
    left: 20,
    top: 564,
    width: 225
  },
  address: {
    height: 54,
    left: 20,
    top: 586,
    width: 225
  },
  desc: {
    fontSize: 26,
    fontSize_lg: 16,
    fontSize_sm: 14,
    fontSize_xs: 12,
    height: 80,
    left: 20,
    top: 181,
    width: 330
  },
  tcode: {
    height: 90,
    left: 265,
    top: 557,
    width: 90
  }
});
function getDefaultData() {
  return {
    templateInfo: {
      img_url: '',
      template_json: cloneDeep(getPositionStyle()),
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_avatar: 1,
      template_type: 1,
      order_num: 0,
      is_show: 1,
      is_deleted: 0
    },
    imageCropper: {
      img_url: '', // 背景图
      imageCropMode: 1,
      backgroundSize: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
    }
  };
}
//获取字体大小
export const getFontSize = (type, str) => {
  if (type === 'head') {
  } else if (type === 'title') {
    let defaultData = getPositionStyle()[type];
    const len = [...str].reduce((pre, cur) => {
      return pre + (cur.charCodeAt() > 255 ? 2 : 1);
    }, 0);
    // console.log(len, defaultTitle); // 打印结果为字符串长度
    return len > 22
      ? len > 48
        ? defaultData.fontSize_xs
        : defaultData.fontSize_sm
      : defaultData.fontSize_lg;
  } else if (type === 'desc') {
    let defaultData = getPositionStyle()[type];
    const len = [...str].reduce((pre, cur) => {
      return pre + (cur.charCodeAt() > 255 ? 2 : 1);
    }, 0);
    return len > 40
      ? len > 92
        ? defaultData.fontSize_xs
        : defaultData.fontSize_sm
      : defaultData.fontSize_lg;
  }
};
export default getDefaultData;
