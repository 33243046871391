<template>
  <div class="customize-card">
    <vh-dialog
      draggable
      title="自定义邀约海报"
      v-if="dialogVisible"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="670px"
    >
      <div class="customize-card-body">
        <!-- 卡片预览，位置拖拽编辑区 -->
        <div class="customize-card-body-card" :key="updateKey">
          <div class="customize-card-body-img">
            <img
              class="customize-card-body-img-bg"
              v-parseImgOss="{ url: domain_url, default: 1 }"
              :src="domain_url"
              v-if="domain_url"
            />
            <div
              class="customize-card-body-img-dom"
              :class="formData.theme_type == 1 ? 'white-color' : 'black-color'"
            >
              <VueDragResize
                :stickSize="12"
                :key="formData.nick_name"
                v-if="formData.is_show_userinfo || formData.is_show_avatar"
                :isActive="activeMap.head"
                :w="positionStyle.head.width"
                :h="positionStyle.head.height"
                :x="positionStyle.head.left"
                :y="positionStyle.head.top"
                :minw="64"
                :minh="44"
                @activated="activateEv('head')"
                @deactivated="deactivateEv('head')"
                @resizing="resize($event, 'head')"
                @dragging="resize($event, 'head')"
                :parentLimitation="true"
                :aspectRatio="true"
              >
                <div class="drag_head" :style="{ 'font-size': positionStyle.head.fontSize + 'px' }">
                  <div class="drag_head-img" v-if="formData.is_show_avatar">
                    <img :src="head_url" />
                  </div>
                  <div class="drag_head-text" v-if="formData.is_show_userinfo">
                    <div
                      :style="{
                        width:
                          positionStyle.head.width -
                          (formData.is_show_avatar ? positionStyle.head.height : 0) -
                          6 +
                          'px'
                      }"
                    >
                      <p>{{ formData.nick_name | nicknameFilter }}</p>
                      <p>邀请您一起参与</p>
                    </div>
                  </div>
                </div>
              </VueDragResize>
              <VueDragResize
                :stickSize="12"
                key="title"
                :isActive="activeMap.title"
                :w="positionStyle.title.width"
                :h="positionStyle.title.height"
                :x="positionStyle.title.left"
                :y="positionStyle.title.top"
                @activated="activateEv('title')"
                @deactivated="deactivateEv('title')"
                @resizing="resize($event, 'title')"
                @dragging="resize($event, 'title')"
                :parentLimitation="true"
                :aspectRatio="true"
              >
                <div
                  class="drag_title"
                  :style="{ 'font-size': positionStyle.title.fontSize + 'px' }"
                >
                  {{ formData.title }}
                </div>
              </VueDragResize>
              <VueDragResize
                :stickSize="12"
                key="time"
                v-show="formData.date_time"
                :isActive="activeMap.time"
                :w="positionStyle.time.width"
                :h="positionStyle.time.height"
                :x="positionStyle.time.left"
                :y="positionStyle.time.top"
                :minh="32"
                @activated="activateEv('time')"
                @deactivated="deactivateEv('time')"
                @resizing="resize($event, 'time')"
                @dragging="resize($event, 'time')"
                :parentLimitation="true"
              >
                <dl class="drag_label">时间：{{ formData.date_time }}</dl>
              </VueDragResize>
              <VueDragResize
                :stickSize="12"
                key="address"
                v-show="formData.location"
                :isActive="activeMap.address"
                :w="positionStyle.address.width"
                :h="positionStyle.address.height"
                :x="positionStyle.address.left"
                :y="positionStyle.address.top"
                :minh="32"
                @activated="activateEv('address')"
                @deactivated="deactivateEv('address')"
                @resizing="resize($event, 'address')"
                @dragging="resize($event, 'address')"
                :parentLimitation="true"
              >
                <dl class="drag_label">地点：{{ formData.location }}</dl>
              </VueDragResize>
              <VueDragResize
                :stickSize="12"
                key="desc"
                v-show="formData.desc"
                :isActive="activeMap.desc"
                :w="positionStyle.desc.width"
                :h="positionStyle.desc.height"
                :x="positionStyle.desc.left"
                :y="positionStyle.desc.top"
                @activated="activateEv('desc')"
                @deactivated="deactivateEv('desc')"
                @resizing="resize($event, 'desc')"
                @dragging="resize($event, 'desc')"
                :parentLimitation="true"
                :aspectRatio="true"
              >
                <div class="drag_desc" :style="{ 'font-size': positionStyle.desc.fontSize + 'px' }">
                  {{ formData.desc }}
                </div>
              </VueDragResize>
              <VueDragResize
                :stickSize="12"
                key="tcode"
                :isActive="activeMap.tcode"
                :w="positionStyle.tcode.width"
                :h="positionStyle.tcode.height"
                :x="positionStyle.tcode.left"
                :y="positionStyle.tcode.top"
                @activated="activateEv('tcode')"
                @deactivated="deactivateEv('tcode')"
                :aspectRatio="true"
                @resizing="resize($event, 'tcode')"
                @dragging="resize($event, 'tcode')"
                :parentLimitation="true"
              >
                <img :src="qrcodeUrl" />
              </VueDragResize>
            </div>
          </div>
          <p class="customize-card-body-img-waring">
            用户昵称、二维码、标题、时间、地点及简介支持移动位置
          </p>
        </div>

        <!-- 卡片信息编辑区 -->
        <div class="customize-card-body-form">
          <vh-form ref="customizeFormData" :model="formData" label-width="70px">
            <vh-form-item label="背景" prop="bgimg">
              <upload
                class="upload__avatar"
                ref="customize_uploader"
                v-model="formData.img_url"
                :domain_url="domain_url"
                :saveData="saveImageData"
                :widthImg="234"
                :heightImg="102"
                :on-success="handleUploadSuccess"
                :on-change="handleUploadChange"
                @delete="deleteImage"
                :auto-upload="false"
              >
                <div slot="tip">
                  <p>建议尺寸：750*1334px</p>
                  <p>小于4M(支持jpg、png、bmp)</p>
                </div>
              </upload>
            </vh-form-item>
            <vh-form-item label="文字" prop="theme_type">
              <vh-radio-group v-model="formData.theme_type">
                <vh-radio :label="0">黑色</vh-radio>
                <vh-radio :label="1">白色</vh-radio>
              </vh-radio-group>
            </vh-form-item>
            <vh-form-item label="昵称" prop="is_show_userinfo">
              <vh-radio-group v-model="formData.is_show_userinfo">
                <vh-radio :label="1">显示</vh-radio>
                <vh-radio :label="0">不显示</vh-radio>
              </vh-radio-group>
            </vh-form-item>
            <vh-form-item label="头像" prop="is_show_avatar">
              <vh-radio-group v-model="formData.is_show_avatar">
                <vh-radio :label="1">显示</vh-radio>
                <vh-radio :label="0">不显示</vh-radio>
              </vh-radio-group>
            </vh-form-item>
            <vh-form-item label="标题" prop="title">
              <vh-input
                v-model="formData.title"
                :maxlength="50"
                v-clearEmoij
                autocomplete="off"
                show-word-limit
              ></vh-input>
            </vh-form-item>
            <vh-form-item label="时间" prop="date_time">
              <vh-input
                v-model="formData.date_time"
                :maxlength="50"
                v-clearEmoij
                autocomplete="off"
                show-word-limit
              ></vh-input>
            </vh-form-item>
            <vh-form-item label="地点" prop="location">
              <vh-input
                v-model="formData.location"
                :maxlength="50"
                v-clearEmoij
                autocomplete="off"
                show-word-limit
              ></vh-input>
            </vh-form-item>
            <vh-form-item label="简介" prop="desc">
              <vh-input
                v-model="formData.desc"
                :maxlength="50"
                v-clearEmoij
                autocomplete="off"
                show-word-limit
              ></vh-input>
            </vh-form-item>
          </vh-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <vh-button size="medium" type="info" plain round @click="resetEvent">恢复默认</vh-button>
        <vh-button
          size="medium"
          type="primary"
          :disabled="isSaveBtnDisabled"
          round
          @click="submitEvent"
        >
          确定
        </vh-button>
      </span>
    </vh-dialog>
    <!-- 裁剪图片弹窗 -->
    <cropper
      ref="invitationCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="750 / 1334"
      :appendToBody="true"
    ></cropper>
  </div>
</template>

<script>
  import { buildOssUploadProcessData, parseQueryString, buildUrlByMode } from '@/utils/utils';
  import Upload from '@/components/Upload/main';
  import VueDragResize from 'vue-drag-resize';
  import Env from '@/api/env';
  import cropper from '@/components/Cropper/index';
  import { cloneDeep } from 'lodash';
  import getDefaultData, { getPositionStyle, getFontSize } from './customCardInfo';
  export default {
    name: 'CustomizeCard',
    components: {
      Upload,
      cropper,
      VueDragResize
    },
    props: {
      // 分享的链接地址
      inviteUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        head_url: '', // 头像
        dialogVisible: false,
        isSaveBtnDisabled: false, // 保存按钮是否禁用
        updateKey: 1,
        activeMap: {
          head: false,
          title: false,
          tcode: false,
          time: false,
          desc: false,
          address: false
        },
        formData: {
          theme_type: 1, //文字颜色 0深色 1浅色
          is_show_userinfo: 1, //用户昵称是否显示 0.不显示 1.显示
          is_show_avatar: 1, //用户头像是否显示 0.不显示 1.显示
          title: '', //标题
          date_time: '', //时间
          desc: '', //简介
          location: '' //地点
        },
        imageCropper: {
          img_url: '' // 背景图
          // imageCropMode: 1,
          // backgroundSize: {
          //   x: 0,
          //   y: 0,
          //   width: 0,
          //   height: 0
          // }
        },
        positionStyle: {
          head: {
            height: 44,
            left: 30,
            top: 26,
            width: 160
          },
          title: {
            height: 136,
            left: 30,
            top: 160,
            width: 300
          },
          desc: {
            height: 136,
            left: 30,
            top: 460,
            width: 300
          },
          time: {
            height: 24,
            left: 30,
            top: 554,
            width: 120
          },
          address: {
            height: 24,
            left: 30,
            top: 600,
            width: 180
          },
          tcode: {
            height: 80,
            left: 264,
            top: 556,
            width: 80
          }
        },
        qrcodeUrl: '',
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    watch: {
      inviteUrl() {
        this.qrcodeUrl = `${Env.staticLinkVo.aliQr}${encodeURIComponent(this.inviteUrl)}`;
      },
      'formData.is_show_userinfo': function (val) {
        this.updateKey++;
        if (!val) {
          this.positionStyle.head.width = this.positionStyle.head.height;
        } else {
          this.positionStyle.head.width = this.formData.is_show_avatar
            ? this.positionStyle.head.height * 3
            : this.positionStyle.head.height * 2 + 12;
        }
      },
      'formData.is_show_avatar': function (val) {
        this.updateKey++;
        if (!val) {
          this.positionStyle.head.width = this.positionStyle.head.height * 2 + 12;
        } else {
          this.positionStyle.head.width = this.formData.is_show_userinfo
            ? this.positionStyle.head.height * 3
            : this.positionStyle.head.height;
        }
      },
      'formData.title': function (val) {
        if (val) {
          let defaultTitle = getPositionStyle().title;

          let transRatio = this.positionStyle.title.width / defaultTitle.width;
          // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
          console.log(val);
          let retFontSize = Math.floor(getFontSize('title', val) * transRatio);
          this.positionStyle.title.fontSize = retFontSize;
        }
      },
      'formData.desc': function (val) {
        if (val) {
          let defaultDesc = getPositionStyle().desc;

          let transRatio = this.positionStyle.desc.width / defaultDesc.width;
          // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
          console.log(val);
          let retFontSize = Math.floor(getFontSize('desc', val) * transRatio);
          this.positionStyle.desc.fontSize = retFontSize;
        }
      }
    },
    computed: {
      domain_url() {
        if (!this.imageCropper.img_url) return '';
        return buildUrlByMode(this.imageCropper.img_url, this.currentMode);
      },
      pathUrl: function () {
        return `interacts/screen-imgs/${dayjs().format('YYYYMM')}`;
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: this.pathUrl,
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    methods: {
      activateEv(type) {
        this.activeMap[type] = true;
      },
      deactivateEv(type) {
        this.activeMap[type] = false;
      },
      // 处理图片
      handlerImageInfo(url) {
        if (!url) {
          this.imageCropper = getDefaultData().imageCropper;
          return;
        }
        this.imageCropper.img_url = url;
        let obj = parseQueryString(url);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      /**
       * 自定义邀请卡保存
       */
      submitEvent() {
        if (!this.domain_url) {
          this.$vhMessage.error('请上传背景图');
          return;
        }
        let saveData = {
          templateInfo: {
            ...this._templateInfo,
            template_json: this.positionStyle,
            img_url: this.domain_url,
            theme_type: this.formData.theme_type, //文字颜色 0深色 1浅色
            is_show_userinfo: this.formData.is_show_userinfo, //用户信息是否显示 0.不显示 1.显示
            is_show_avatar: this.formData.is_show_avatar
          },
          cardInfo: {
            ...this._cardInfo,
            title: this.formData.title, //标题
            date_time: this.formData.date_time, //时间
            desc: this.formData.desc, //简介
            location: this.formData.location //地点
          }
        };
        console.log('saveCustomizeInfo', saveData);
        this.$emit('saveCustomizeInfo', saveData, this.isNew);
        this.dialogVisible = false;
      },
      /**
       * 恢复默认设置
       */
      resetEvent() {
        this.formData.theme_type = 0;
        this.formData.is_show_userinfo = 1;
        this.formData.is_show_avatar = 1;
        this.$nextTick(() => {
          this.positionStyle = cloneDeep(getDefaultData().templateInfo.template_json);
          if (this.formData.title) {
            this.positionStyle.title.fontSize = getFontSize('title', this.formData.title);
          }
          if (this.formData.desc) {
            this.positionStyle.desc.fontSize = getFontSize('desc', this.formData.desc);
          }
          this.updateKey++;
        });
      },
      /**
       *
       * @param {*} newRect 拖动邀请卡位置信息
       * @param {*} type 拖动的元素类型
       */
      resize(newRect, type) {
        console.log('newRect', newRect, type);
        this.positionStyle[type] = newRect;
        if (type === 'head') {
          let defaultHead = getPositionStyle().head;
          let transRatio = newRect.height / defaultHead.height;
          this.positionStyle.head.fontSize = Math.floor(defaultHead.fontSize * transRatio);
        } else if (type === 'title') {
          let defaultTitle = getPositionStyle().title;
          let transRatio = newRect.width / defaultTitle.width;
          // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
          let retFontSize = Math.floor(getFontSize(type, this.formData.title) * transRatio);
          this.positionStyle.title.fontSize = retFontSize;
        } else if (type === 'desc') {
          let defaultDesc = getPositionStyle().desc;
          let transRatio = newRect.width / defaultDesc.width;
          // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
          let retFontSize = Math.floor(getFontSize(type, this.formData.desc) * transRatio);
          this.positionStyle.desc.fontSize = retFontSize;
        }
      },
      /**
       * 显示自定义邀请卡弹框
       */
      show(cardInfo, templateInfo) {
        console.log(cardInfo, templateInfo);
        this._cardInfo = cloneDeep(cardInfo); // 备份恢复默认设置的时候需要用
        this.head_url = this._cardInfo.head_url;
        if (templateInfo) {
          this.isNew = false;
          this._templateInfo = cloneDeep(templateInfo); // 备份恢复默认设置的时候需要用
        } else {
          this.isNew = true;
          this._templateInfo = getDefaultData().templateInfo;
        }
        console.log(this._templateInfo);
        if (this._templateInfo.is_show_avatar === null) {
          this._templateInfo.is_show_avatar = this._templateInfo.is_show_userinfo;
        }

        this.dialogVisible = true;
        this.extendData();
      },
      /**
       * 继承编辑页面的邀请卡数据
       * isImgUrlExtend，继承数据的时候，背景图片是否继承
       */
      extendData(isImgUrlExtend = true) {
        this.formData = {
          theme_type: this._templateInfo.theme_type, //文字颜色 0深色 1浅色
          is_show_userinfo: this._templateInfo.is_show_userinfo, //用户信息是否显示 0.不显示 1.显示
          is_show_avatar: this._templateInfo.is_show_avatar,
          nick_name: this._cardInfo.nick_name,
          title: this._cardInfo.title, //标题
          desc: this._cardInfo.desc, //标题
          date_time: this._cardInfo.date_time, //时间
          location: this._cardInfo.location //地点
        };

        if (isImgUrlExtend) {
          this.handlerImageInfo(this._templateInfo.img_url);
        }

        if (this._templateInfo) {
          this.positionStyle = cloneDeep(this._templateInfo.template_json);
          if (this.formData.title) {
            this.positionStyle.title.fontSize = getFontSize('title', this.formData.title);
          }
          if (this.formData.desc) {
            this.positionStyle.desc.fontSize = getFontSize('desc', this.formData.desc);
          }
        }
      },
      /**
       * 裁剪背景图
       * @param {*} cropperData
       * @param {*} url
       * @param {*} mode
       */
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.customize_uploader.upload();
        });
      },
      /**
       * 重新选择文件
       */
      resetChooseFile() {
        this.$refs.customize_uploader.resetChooseFile();
      },
      /**
       * 上传背景图成功
       * @param {*} res
       * @param {*} file
       */
      handleUploadSuccess(res, file) {
        this.isSaveBtnDisabled = false;
        // 文件上传成功，保存信息
        if (res.data) {
          this.imageCropper.img_url = res.data.domain_url;
        }
      },
      handleUploadChange(file, fileList) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, ['png', 'jpeg', 'bmp'], 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.invitationCropper.showModel(e.target.result);
            };
          });
        }
      },
      // 删除图片
      deleteImage() {
        this.imageCropper.img_url = '';
      }
    }
  };
</script>

<style lang="less" scoped>
  .customize-card-body {
    display: flex;
    &-img {
      width: 375px;
      height: 667px;
      zoom: 0.588;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: content-box;
      &-dom {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
        .vdr.active * {
          cursor: move;
        }
      }
      &-bg {
        width: 100%;
        height: 100%;
      }
      &-waring {
        font-size: 12px;
        line-height: 16px;
        padding-top: 10px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    &-form {
      flex: 1;
      ::v-deep .vh-upload--picture-card {
        height: 102px;
      }
      ::v-deep .vh-form-item {
        margin-bottom: 22px;
        .vh-input__inner {
          height: 36px;
          line-height: 36px;
        }
        .vh-form-item__label {
          line-height: 36px;
        }
        .vh-form-item__content {
          line-height: 36px;
        }
      }
    }
    &-card {
      width: 222px;
      height: 360px;
      margin-right: 0px;
      position: relative;
    }
    .drag_label {
      display: flex;
      font-size: 12px;
      line-height: 16px;
    }
    .drag_head {
      display: flex;
      height: 100%;
      width: 100%;
      &-img {
        height: 100%;
        img {
          height: 100%;
          border-radius: 50%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      &-text {
        padding-left: 6px;
        flex: 1;
        line-height: 1.2;
        display: flex;
        > div {
          margin: auto 0;
          font-size: 1em;
          > p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .drag_title {
      width: 100%;
      height: 100%;
      // padding: 10px;
      font-size: 30px;
      line-height: 1.3;
      word-break: break-word;
      font-weight: 600;
    }
    .drag_desc {
      line-height: 1.5;
      word-break: break-word;
    }
    .white-color {
      color: #fff;
    }
    .black-color {
      color: #000;
    }
  }
</style>
