// 邀请卡默认的十套模板的数据，创建邀请卡的场景会用到
export default function getDefaultTemplates() {
  return [
    {
      template_id: 1,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card1.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 0,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 1,
      is_selected: 1
    },
    {
      template_id: 2,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card2.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 1,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 2,
      is_selected: 0
    },
    {
      template_id: 3,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card3.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 2,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 3,
      is_selected: 0
    },
    {
      template_id: 4,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card4.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 3,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 4,
      is_selected: 0
    },
    {
      template_id: 5,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card5.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 4,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 5,
      is_selected: 0
    },
    {
      template_id: 6,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card6.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 5,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 6,
      is_selected: 0
    },
    {
      template_id: 7,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card7.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 6,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 7,
      is_selected: 0
    },
    {
      template_id: 8,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card8.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 7,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 8,
      is_selected: 0
    },
    {
      template_id: 9,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card9.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 8,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 9,
      is_selected: 0
    },
    {
      template_id: 10,
      img_url: 'https://cnstatic01.e.vhall.com/common-static/images/invite-card/card10.png',
      template_json: '',
      theme_type: 0,
      is_show_userinfo: 1,
      is_show_qrcode: 1,
      template_type: 0,
      order_num: 9,
      is_show: 1,
      is_deleted: 0,
      theme_remark: 10,
      is_selected: 0
    }
  ];
}
