<template>
  <div>
    <div class="upload">
      <upload
        ref="invitation_uploader"
        :domain_url="domain_url"
        :saveData="saveImageData"
        :on-success="handleuploadSuccess"
        :isShowDel="false"
        :heightImg="160"
        :on-change="handleUploadChange"
        @delete="deleteImg"
        :auto-upload="false"
      >
        <div slot="tip">
          <p>建议尺寸：1280*720px</p>
          <p>小于4M（支持jpg、png、bmp）</p>
        </div>
      </upload>
      <div class="upload-tip">建议尺寸：1280*720px，小于4M，支持jpg、png、bmp</div>
      <!-- <div class="image_cropper">
        <div class="image_cropper_item">
          <span>模糊程度</span>
          <vh-slider
            v-model="imageCropper.blurryDegree"
            :max="10"
            :disabled="!imageCropper.img_url"
            style="width: 300px"
          ></vh-slider>
          <span class="wid_block">{{ imageCropper.blurryDegree }}</span>
        </div>
        <div class="image_cropper_item">
          <span>背景亮度</span>
          <vh-slider
            v-model="imageCropper.lightDegree"
            :max="20"
            :disabled="!imageCropper.img_url"
            style="width: 300px"
          ></vh-slider>
          <span class="wid_block">{{ imageCropper.lightDegree }}</span>
        </div>
      </div> -->
    </div>
    <!-- 裁剪组件 -->
    <cropper
      ref="invitationCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="1280 / 720"
      :appendToBody="true"
    ></cropper>
  </div>
</template>
<script>
  import {
    buildOssUploadProcessData,
    parseQueryString,
    buildUrlByMode
  } from '@/utils/utils';
  import upload from '@/components/Upload/main';
  import cropper from '@/components/Cropper/index';
  export default {
    data() {
      return {
        // rowImgUrl: '',
        imageCropper: {
          img_url: ''
          // blurryDegree: 0,
          // lightDegree: 10,
          // imageCropMode: 1,
          // backgroundSize: {
          //   x: 0,
          //   y: 0,
          //   width: 0,
          //   height: 0
          // }
        },
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    watch: {
      domain_url: {
        handler() {
          this.$emit('onChangePic', this.domain_url);
        }
      }
    },
    components: {
      upload,
      cropper
    },
    computed: {
      domain_url() {
        if (!this.imageCropper.img_url) return '';
        return buildUrlByMode(this.imageCropper.img_url, this.currentMode);
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'interacts/invite-card-imgs',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    methods: {
      setImgUrl(url) {
        if (url) {
          this.handlerImageInfo(url);
        }
      },
      // 处理图片
      handlerImageInfo(url) {
        this.imageCropper.img_url = url;
        let obj = parseQueryString(url);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      deleteImg() {
        this.imageCropper.img_url = '';
      },
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.invitation_uploader.upload();
        });
      },
      resetChooseFile() {
        this.$refs.invitation_uploader.resetChooseFile();
      },
      handleuploadSuccess(res, file) {
        if (res.data) {
          this.imageCropper.img_url = res.data.domain_url;
        }
      },
      handleUploadChange(file, fileList) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, ['png', 'jpeg', 'bmp'], 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.invitationCropper.showModel(e.target.result);
            };
          });
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .upload ::v-deep .vh-upload--picture-card {
    height: 169px;
    .tips p {
      transform: scale(0.95);
      line-height: 14px;
    }
  }
  .upload-tip {
    font-size: 12px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 12px;
  }
  // .image_cropper {
  //   width: 100%;
  //   margin-top: 16px;
  //   &_item {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     span {
  //       color: #595959;
  //     }
  //     .wid_block {
  //       display: inline-block;
  //       width: 16px;
  //     }
  //   }
  // }
</style>
