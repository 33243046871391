<template>
  <div class="invitation-edit">
    <vh-form ref="formData" :model="cardInfo" label-width="80px" :rules="rules">
      <vh-form-item label="风格" prop="seq">
        <div class="invitation-cardlist">
          <draggable
            draggable=".draggable-item"
            v-bind="{
              animation: 200,
              disabled: false
            }"
            @change="sortTemplates"
            :move="onMove"
            v-model="templates"
          >
            <!--自定义风格-->
            <div
              slot="header"
              :class="[
                'invitation-cardlist-item',
                'invitation-cardlist-first',
                templates.length >= 20 ? 'invitation-cardlist-item-disable' : ''
              ]"
              @click="showCustomizeDialog(undefined)"
            >
              <i class="iconfont-v3 saasline-plus"></i>
            </div>
            <div
              v-for="(item, index) in templates"
              :class="[
                'invitation-cardlist-item',
                cardInfo.active_template_id === item.template_id
                  ? 'invitation-cardlist-item__active'
                  : '',
                'draggable-item',
                !item.is_show ? 'draggable-forbid' : ''
              ]"
              @click="changeType(item)"
              :key="item.template_id"
            >
              <img
                v-parseImgOss="{ url: item.img_url, default: 1 }"
                v-if="item.img_url"
                :src="item.img_url"
              />
              <!-- 选中状态 -->
              <label class="img-tangle" v-if="cardInfo.active_template_id === item.template_id">
                <img src="@/common/images/icon-choose.png" alt="" />
              </label>
              <!-- 常显遮罩层 -->
              <div class="model-content model-content-visible" v-if="!item.is_show">
                <!-- <div class="model-bg"></div> -->
                <span class="model-text">
                  <span>已隐藏</span>
                </span>
              </div>
              <!-- hover显示 -->
              <div class="model-content">
                <!-- <div class="model-bg"></div> -->
                <div
                  class="model-delete"
                  v-if="item.template_type"
                  @click.stop="deleteCard(item, index)"
                >
                  <i class="vh-icon-close"></i>
                </div>
                <div class="model-text">
                  <span v-show="item.template_type" @click.stop="showCustomizeDialog(item)">
                    更换
                  </span>
                  <span @click.stop="isShowChange(item, index)">
                    {{ item.is_show ? '隐藏' : '显示' }}
                  </span>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="invitation-cardlist-tip">
          拖拽调整邀约海报顺序，支持进行隐藏，隐藏后观看端将不展示
        </div>
      </vh-form-item>
      <vh-form-item label="标题" prop="title">
        <vh-input
          v-model="cardInfo.title"
          :maxlength="cardInfo.active_template_type ? 50 : 16"
          v-clearEmoij
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="时间" prop="date_time">
        <vh-input
          v-model="cardInfo.date_time"
          :maxlength="cardInfo.active_template_type ? 50 : 20"
          v-clearEmoij
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="地点" prop="location">
        <vh-input
          v-model="cardInfo.location"
          :maxlength="cardInfo.active_template_type ? 50 : 20"
          v-clearEmoij
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="简介" prop="desc" v-if="cardInfo.active_template_type">
        <vh-input
          v-model="cardInfo.desc"
          :maxlength="50"
          v-clearEmoij
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="封面" v-show="[1, 4, 8].includes(cardInfo.seq)">
        <add-background ref="addBackground" @onChangePic="onChangePic"></add-background>
      </vh-form-item>
      <vh-form-item label="修改昵称" v-if="sourceType == 1" class="upNicknameBox">
        <vh-switch
          class="nickNameSwitch"
          v-model="cardInfo.nickname_switch"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          @change="handleNickNameSwitch"
          active-text=""
        ></vh-switch>
        <span class="nickNameSwitchDesc" :class="{ active: cardInfo.nickname_switch }">
          {{ cardInfo.nickname_switch ? '已开启' : '开启后' }}，观众可修改昵称进行海报的分享
          <vh-popover popper-class="inviteCardNicknamePreview" placement="top" trigger="hover">
            <img src="../img/preview.png" alt="" />
            <vh-button slot="reference" type="text">查看效果</vh-button>
          </vh-popover>
        </span>
      </vh-form-item>
      <vh-form-item label="设置提示" v-if="cardInfo.nickname_switch && sourceType == 1">
        <vh-input
          v-model="cardInfo.nickname_tips"
          :maxlength="20"
          v-clearEmoij
          autocomplete="off"
          show-word-limit
          placeholder="请输入姓名"
        ></vh-input>
      </vh-form-item>

      <!-- 显示链接 -->
      <vh-form-item label="复制链接" class="part-std-item auto-height" prop="copy_url_beginning">
        <div class="content-title">
          <vh-switch
            v-model="cardInfo.copy_url_switch"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            :active-value="1"
            :inactive-value="0"
            active-text=""
          ></vh-switch>
          <span class="switch-label">
            {{ cardInfo.copy_url_switch ? '已开启' : '开启后' }}，观众可复制链接进行邀约分享
            <vh-popover popper-class="copy-url-preview" placement="top" trigger="hover">
              <img
                src="../img/copy_url_preview.png"
                style="width: 240px; object-fit: contain"
                alt=""
              />
              <vh-button slot="reference" type="text">查看效果</vh-button>
            </vh-popover>
          </span>
        </div>
        <div class="content-body" v-show="cardInfo.copy_url_switch > 0" style="margin-top: 16px">
          <vh-input
            v-model.trim="cardInfo.copy_url_beginning"
            type="textarea"
            resize="none"
            maxlength="100"
            show-word-limit
          ></vh-input>
          <div class="preview-box">
            <label class="preview-label">预览效果:</label>
            <div class="preview-content">{{ copyUrlContentPreview }}</div>
          </div>
        </div>
      </vh-form-item>

      <vh-form-item
        label="扫码预览"
        class="upNicknameBox"
        v-show="sourceType == 1 && userPermission['cms.show_global_embed_link']"
      >
        <vh-radio-group v-model="scanCodePreview" @change="changeLink" v-removeAriaHidden>
          <vh-radio :label="0">活动链接</vh-radio>
          <vh-radio :label="1" :disabled="!businessSiteLink">全局嵌入链接</vh-radio>
        </vh-radio-group>
      </vh-form-item>
      <vh-form-item>
        <vh-button type="primary" class="length152" round v-preventReClick @click="onSubmit">
          保存
        </vh-button>
        <vh-button
          v-if="sourceType == 2"
          type="info"
          class="length152"
          round
          plain
          v-preventReClick
          @click="onCancel"
        >
          取消
        </vh-button>
      </vh-form-item>
    </vh-form>
    <!--自定义邀请卡风格弹窗-->
    <customize-card
      :cardInfo="cardInfo"
      :inviteUrl="inviteUrl"
      ref="customizeCard"
      @saveCustomizeInfo="saveCustomizeInfo"
    ></customize-card>
  </div>
</template>

<script>
  import addBackground from './../../components/imgBackground';
  import CustomizeCard from './CustomizeCard.vue';
  import html2canvas from 'html2canvas';
  import { cloneDeep } from 'lodash';
  import draggable from 'vuedraggable';
  import { loadImage } from '@/utils/utils';
  import getDefaultTemplates from './defaultTemplates';
  import { defaultAvatar } from '@/utils/ossImgConfig';
  // import { cardInfoMockData } from './demoTemplateData';
  import EmbedLinkMixin from '@/views/Cms/embedLinkMixin';
  import { sessionOrLocal } from '@/utils/utils';
  import { getPositionStyle, getFontSize } from './customCardInfo';

  const defaultCopyUrlBeginning = '邀请您观看直播活动';

  export default {
    name: 'InvitationCardEdit',
    components: {
      addBackground,
      draggable,
      CustomizeCard
    },
    mixins: [EmbedLinkMixin],
    props: {
      // 邀请卡id，如果是编辑才会传
      cardId: {
        type: [Number, String],
        required: false
      },
      // 邀请卡默认信息，如果是新建才会传（cardId为空时生效）
      defaultCardInfo: {
        type: Object,
        required: false
      },
      // sourceType对应的id，webcasting 为活动id，event 为站点id
      sourceId: {
        type: [Number, String],
        required: true
      },
      // 数据来源id 类型 1.活动 2.落地页
      sourceType: {
        type: Number,
        required: true
      },
      // 分享的链接地址
      shareUrl: {
        type: String,
        required: true
      },
      // 语言类型
      languageType: {
        type: Number,
        required: false,
        default: 0
      }
    },
    data() {
      const locationValidate = (rule, value, callback) => {
        console.log(value, this.cardInfo.active_template_type, 1);
        if (value && this.cardInfo.active_template_type == 0 && value.length > 20) {
          callback && callback('地点在20个字符以内');
        } else if (value && this.cardInfo.active_template_type == 1 && value.length > 50) {
          callback && callback('地点在50个字符以内');
        } else {
          callback();
        }
      };
      const timeValidate = (rule, value, callback) => {
        console.log(value, this.cardInfo.active_template_type, 2);
        if (value && this.cardInfo.active_template_type == 0 && value.length > 20) {
          callback && callback('时间在20个字符以内');
        } else if (value && this.cardInfo.active_template_type == 1 && value.length > 50) {
          callback && callback('时间在50个字符以内');
        } else {
          callback();
        }
      };
      const titleValidate = (rule, value, callback) => {
        console.log(value, this.cardInfo.active_template_type, 3);
        if (value && this.cardInfo.active_template_type == 0 && value.length > 16) {
          callback && callback('标题在16个字符以内');
        } else if (value && this.cardInfo.active_template_type == 1 && value.length > 50) {
          callback && callback('标题在50个字符以内');
        } else {
          callback();
        }
      };
      const descValidate = (rule, value, callback) => {
        console.log(value, this.cardInfo.active_template_type, 4);
        if (value && this.cardInfo.active_template_type == 0 && value.length > 20) {
          callback && callback('简介在20个字符以内');
        } else if (value && this.cardInfo.active_template_type == 1 && value.length > 50) {
          callback && callback('简介在50个字符以内');
        } else {
          callback();
        }
      };
      const copyUrlValidate = (rule, value, callback) => {
        if (this.cardInfo.copy_url_switch > 0 && value.length === 0) {
          callback && callback('请设置「复制链接」后分享给他人的分享语');
        } else {
          callback();
        }
      };
      return {
        cardInfo: {},
        scanCodePreview: 0,
        //风格背景图列表
        templates: [],
        liveDetail: {},
        inviteUrl: '',
        rules: {
          title: [{ validator: titleValidate, trigger: 'blur' }],
          location: [{ validator: locationValidate, trigger: 'blur' }],
          desc: [{ validator: descValidate, trigger: 'blur' }],
          date_time: [{ validator: timeValidate, trigger: 'blur' }],
          copy_url_beginning: [{ validator: copyUrlValidate, trigger: 'blur' }]
        },
        codePreviewChose: false,
        userPermission: {},
        inviteCode: '', // 邀请码
        copyUrlShortLink: '' // 复制链接短链
      };
    },
    async created() {
      await this.getWebinarInfo();
      this.getInviteCardInfo();
      this.userPermission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      if (this.userPermission['cms.show_global_embed_link']) {
        this.getBusinessDomainConfigList();
      }
    },
    watch: {
      cardInfo: {
        handler(val) {
          if (val) {
            this.generateStandardData();
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      copyUrlContentPreview() {
        let userInfo = sessionOrLocal.get('userInfo');
        if (typeof userInfo === 'string') {
          userInfo = JSON.parse(userInfo);
        }
        const nickName = userInfo?.nick_name || ', ';
        // 某某某邀请您观看直播活动《title》, 开播时间：{time}, 点击进入{url}
        let content = `${nickName}${this.cardInfo.copy_url_beginning || ''}`;
        if (this.cardInfo.title) content += `《${this.cardInfo.title}》`;
        content += ', ';
        if (this.cardInfo.date_time) content += `开播时间: ${this.cardInfo.date_time}, `;
        content += `点击进入：${this.copyUrlShortLink || ''} `;
        return content;
      }
    },
    methods: {
      changeLink(value) {
        this.setInviteUrl();
        this.generateStandardData();

        // 生成复制链接的短链
        this.genCopyUrlShortLink();
      },
      onCancel() {
        this.$emit('cancel');
      },
      //禁止拖动到id为1的对象
      onMove(e) {
        // console.log(e);
        // console.log(e.relatedContext.element.is_show, e.draggedContext.element.is_show);
        if (!e.relatedContext.element.is_show || !e.draggedContext.element.is_show) return false;
        return true;
      },
      async getWebinarInfo() {
        if (this.sourceType == 2) return Promise.resolve();
        try {
          // webinar/info调整-正常的信息展示使用 0
          const result = await this.$fetch('getWebinarInfo', {
            webinar_id: this.sourceId,
            is_rehearsal: 0
          });
          if (result.code == 200 && result.data) {
            this.liveDetail = result.data;
          }
        } catch {
          this.liveDetail = {};
        }
      },
      deleteCard(item, index) {
        this.templates.splice(index, 1);
        if (item.template_id == this.cardInfo.active_template_id) {
          this.cardInfo.active_template_id = this.templates[0].template_id;
          this.cardInfo.active_template_type = this.templates[0].template_type;
        }
      },
      //切换风格模板
      changeType(item) {
        console.log(item);
        this.cardInfo.active_template_id = item.template_id;
        this.cardInfo.active_template_type = item.template_type;
        this.setCardSeq();
        this.$nextTick(() => {
          this.$refs.formData.validate();
        });
      },
      //隐藏、显示卡片
      isShowChange(item, index) {
        item.is_show = item.is_show ? 0 : 1;
        this.templates.splice(index, 1);
        if (!item.is_show) {
          this.templates.push(item);
        } else {
          const lastIndex = this.templates.findIndex(item => !item.is_show);
          if (lastIndex > -1) {
            this.templates.splice(lastIndex, 0, item);
          } else {
            this.templates.push(item);
          }
        }
        this.sortTemplates();
      },
      //自定义邀请卡弹框保存数据
      saveCustomizeInfo({ templateInfo, cardInfo }, isNew) {
        if (isNew) {
          let newTemplate = {
            ...templateInfo,
            template_id: new Date().getTime(),
            isNew: true,
            order_num: 0,
            theme_remark: 0
          };
          this.templates = this.templates.map(item => ({ ...item, order_num: item.order_num + 1 }));
          this.templates.unshift(newTemplate);
        } else {
          this.templates.some((item, index) => {
            if (item.template_id == templateInfo.template_id) {
              this.templates.splice(index, 1, { ...item, ...templateInfo });
              return true;
            }
          });
        }

        const defaultParams = {
          cover_img: '',
          date_time: '',
          language_type: 0,
          location: '',
          desc: '',
          source_type: 1,
          title: ''
        };

        this.cardInfo = {
          ...defaultParams,
          ...this.cardInfo,
          ...cardInfo
        };

        if (isNew) {
          this.cardInfo.active_template_id = this.templates[0].template_id;
          this.cardInfo.active_template_type = this.templates[0].template_type;
        } else {
          this.cardInfo.active_template_id = templateInfo.template_id;
          this.cardInfo.active_template_type = templateInfo.template_type;
        }

        this.setCardSeq();
      },
      // 新增或者编辑自定义邀请卡，item存在就是编辑，不存在是新增
      showCustomizeDialog(item) {
        if (!item && this.templates.length >= 20) {
          this.$vhMessage.warning('最多添加10个自定义邀请卡');
          return;
        }
        this.$refs.customizeCard.show(this.cardInfo, item);
      },
      getInviteCardInfo() {
        if (this.sourceType == 1 || (this.sourceType == 2 && this.cardId)) {
          this.fetchInviteCardInfo();
        } else {
          // eventhub 创建的时候调用
          this.setDefatulCardInfo();
        }
      },
      // 创建邀请卡场景，设置邀请卡默认信息
      setDefatulCardInfo() {
        // 二维码
        this.inviteUrl = this.shareUrl;
        // 生成短链
        this.genCopyUrlShortLink();

        const templates = getDefaultTemplates();
        this.cardInfo = {
          ...(this.defaultCardInfo || {}),
          language_type: this.languageType || 0,
          templates,
          active_template_id: templates[0].template_id,
          active_template_type: templates[0].template_type,
          copy_url_switch: 0,
          copy_url_beginning: defaultCopyUrlBeginning
        };

        const title = this.cardInfo.title;
        if (title && title.length && title.length > 16) {
          this.cardInfo.title = title.slice(0, 16);
        }

        this.cardInfo.head_url = this.cardInfo.head_url || defaultAvatar;

        this.templates = templates;

        this.setCardSeq();

        // 通知子组件邀请卡封面
        if (this.cardInfo.cover_img) {
          this.$refs.addBackground.setImgUrl(this.cardInfo.cover_img);
        }
        // 通知父组件渲染组件，解决图片加载慢导致表单渲染完之后，图片跳出来的问题
        this.loadAllTemplateImage(this.templates);
      },
      setInviteUrl() {
        if (this.sourceType == 1 && this.userPermission['cms.show_global_embed_link']) {
          if (this.scanCodePreview == 1 && this.businessSiteLink) {
            // dep_id值为活动id,dep_type为1时代表直播类型
            const DEP_TYPE_NUM = {
              webinar: 1, // 直播间
              page: 4, // 单场大会
              series: 5, // 系列会
              website: 6 // 聚合主站
            };
            const queryStr = encodeURIComponent(
              `embedMode=1&dep_id=${this.$route.params.str}&dep_type=${DEP_TYPE_NUM['webinar']}&invite_code=${this.inviteCode}&invite_way=1`
            );
            this.inviteUrl = `${this.businessSiteLink}?dep_params=${queryStr}`;
          } else {
            this.inviteUrl = `${this.shareUrl}?invite_code=${this.inviteCode}&invite_way=1`;
          }
        } else {
          this.inviteUrl = `${this.shareUrl}?invite_code=${this.inviteCode}&invite_way=1`;
        }
      },
      // 通过接口获取邀请卡详情
      fetchInviteCardInfo() {
        let params = {
          source_id: +this.sourceId,
          source_type: this.sourceType, // 1.webcasting 2.落地页
          language_type: this.languageType || 0 //   0.中文邀请卡，1.英文邀请卡
        };
        this.$fetch('getCardDetailInfo', params, {
          'Content-Type': 'application/json'
        }).then(res => {
          this.cardInfo = {
            ...res.data,
            desc: res.data?.introduction,
            active_template_id: res.data.templates[0].template_id,
            active_template_type: res.data.templates[0].template_type,
            nickname_switch: res.data.nickname_switch ? true : false,
            copy_url_switch: res.data?.copy_url_switch ? 1 : 0,
            copy_url_beginning: res.data?.copy_url_beginning || defaultCopyUrlBeginning
          };

          this.scanCodePreview = res.data.scan_code_preview || 0;
          this.inviteCode = res.data.invite_code; // 二维码
          this.setInviteUrl();

          // 生成复制链接的短链
          this.genCopyUrlShortLink();

          this.templates = res.data.templates.map(item => {
            if (item.is_selected) {
              this.cardInfo.active_template_id = item.template_id;
              this.cardInfo.active_template_type = item.template_type;
            }
            return {
              ...item,
              template_json: item.template_json && JSON.parse(item.template_json)
            };
          });
          this.setCardSeq();

          // 通知父组件邀请卡开启状态
          this.$emit('switchStatusChange', Boolean(res.data.switch_status));
          // 通知子组件邀请卡封面
          if (res.data.cover_img) {
            this.$refs.addBackground.setImgUrl(res.data.cover_img);
          }
          // 通知父组件渲染组件，解决图片加载慢导致表单渲染完之后，图片跳出来的问题
          this.loadAllTemplateImage(this.templates);
        });
      },
      loadAllTemplateImage(templates) {
        const taskList = [];
        templates.forEach(item => {
          taskList.push(loadImage(item.img_url));
        });
        // 解决在 cms 中 Promise.allSettled 报 undefined 的问题
        if (!Promise.allSettled) {
          const rejectHandler = reason => ({ status: 'rejected', reason });
          const resolveHandler = value => ({ status: 'fulfilled', value });
          Promise.allSettled = function (promises) {
            const convertedPromises = promises.map(p =>
              Promise.resolve(p).then(resolveHandler, rejectHandler)
            );
            return Promise.all(convertedPromises);
          };
        }
        Promise.allSettled(taskList).finally(() => {
          this.$emit('imgLoadCompleted');
        });
      },
      sortTemplates() {
        this.templates.forEach((item, index) => {
          item.order_num = index;
        });
      },
      generateStandardData() {
        const activeItem = this.getActiveTemplate();
        let pStyle = cloneDeep(activeItem?.template_json);
        if (activeItem && activeItem.template_type) {
          //定义模板
          if (this.cardInfo.title) {
            let defaultTitle = getPositionStyle().title;
            let transRatio = pStyle.title.width / defaultTitle.width;
            // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
            let retFontSize = Math.floor(getFontSize('title', this.cardInfo.title) * transRatio);
            pStyle.title.fontSize = retFontSize;
          }
          //定义模板
          if (this.cardInfo.desc) {
            let defaultDesc = getPositionStyle().desc;
            let transRatio = pStyle.desc.width / defaultDesc.width;
            // 加Math.floor的目的是为了解决因为小数点导致的换行的问题
            let retFontSize = Math.floor(getFontSize('desc', this.cardInfo.desc) * transRatio);
            pStyle.desc.fontSize = retFontSize;
          }
        }

        const standardData = {
          cardInfo: {
            seq: this.setCardSeq(),
            bgUrl: activeItem?.img_url || '', //模板背景
            avatar: this.cardInfo.head_url || '', //用户头像
            nickname: this.cardInfo.nick_name || '', //用户昵称
            poster: this.cardInfo.cover_img || '', //活动封面
            title: this.cardInfo.title || '',
            desc: this.cardInfo.desc || '',
            time: this.cardInfo.date_time || '',
            addr: this.cardInfo.location || '',
            code: this.inviteUrl || '',
            isShowUserinfo: activeItem?.is_show_userinfo, //是否展示用户信息 0.不显示 1.显示
            isShowQrcode: activeItem?.is_show_qrcode, //是否展示二维码 0.不显示 1.显示
            isShowAvatar:
              activeItem?.is_show_avatar === null
                ? activeItem?.is_show_userinfo
                : activeItem?.is_show_avatar,
            themeType: activeItem?.theme_type,
            template_type: activeItem?.template_type,
            language: this.cardInfo.language_type || 0
          },
          positionStyle: pStyle
        };
        this.$emit('changeCardInfo', standardData);
        console.log('standardData------>', standardData);
        return standardData;
      },
      getActiveTemplate() {
        return this.templates
          ? this.templates.find(item => item.template_id === this.cardInfo.active_template_id)
          : {};
      },
      setCardSeq() {
        const activeItem = this.getActiveTemplate();
        this.cardInfo.seq = activeItem?.template_type == 0 ? activeItem.theme_remark : 0;
        return this.cardInfo.seq;
      },
      // 封面图修改
      onChangePic(url) {
        this.cardInfo.cover_img = url;
      },
      // 保存邀请卡信息
      onSubmit() {
        this.$refs.formData.validate(valid => {
          if (valid) {
            let showNum = 0;
            const template_detail = this.templates.map(item => {
              if (item.is_show) showNum++;
              return {
                template_id: item.isNew ? null : item.template_id, //模板id
                is_show: item.is_show, //是否观看端展示 0.不展示 1.展示
                img_url: this.$parseURL(item.img_url).path, //背景图片
                theme_type: item.theme_type, //主题样式类型 0.深色 1.浅色
                is_show_userinfo: item.is_show_userinfo, //是否展示用户信息 0.不显示 1.显示
                is_show_avatar: item.is_show_avatar,
                is_show_qrcode: item.is_show_qrcode == 0 ? 0 : 1, //是否展示二维码 0.不显示 1.显示
                template_type: item.template_type, //模板类型 0.系统模板 1.自定义模板
                order_num: item.order_num, //排序
                theme_remark: item.theme_remark,
                template_json: JSON.stringify(item.template_json || {}),
                is_selected: this.cardInfo.active_template_id == item.template_id ? 1 : 0
              };
            });
            if (!showNum) {
              this.$vhMessage({
                message: '请选择1个邀约海报处于显示状态',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              return;
            }
            const params = {
              source_id: +this.sourceId,
              source_type: this.sourceType,
              language_type: this.cardInfo.language_type || 0,
              date_time: this.cardInfo.date_time,
              title: this.cardInfo.title,
              introduction: this.cardInfo.desc,
              location: this.cardInfo.location,
              nickname_tips: this.cardInfo.nickname_tips,
              nickname_switch: this.cardInfo.nickname_switch ? 1 : 0,
              cover_img: this.cardInfo.cover_img
                ? this.$parseURL(this.cardInfo.cover_img).path
                : '',
              template_detail: JSON.stringify(template_detail),
              head_url: this.cardInfo.head_url,
              scan_code_preview: this.scanCodePreview,
              copy_url_switch: this.cardInfo.copy_url_switch,
              copy_url_beginning: this.cardInfo.copy_url_beginning
            };
            if (this.sourceType == 2) {
              delete params.nickname_tips;
              delete params.nickname_switch;
            }
            // if (this.cardInfo.nickname_switch) {
            //   params.nickname_tips = this.cardInfo.nickname_tips;
            // }
            // 字段里面若对象值为空，也要传递。
            this.$fetch('editCardInfo', params, {
              'Content-Type': 'application/json'
            })
              .then(res => {
                if (res.code == 200) {
                  this.$vhMessage({
                    message: `保存数据成功`,
                    showClose: true,
                    // duration: 0,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                }
                this.$emit('inviteCardSave', {
                  inviteSourceId: +this.sourceId,
                  // 数据来源id 类型 1.活动 2.落地页
                  inviteSourceType: this.sourceType,
                  language_type: this.cardInfo.language_type || 0,
                  date_time: this.cardInfo.date_time,
                  title: this.cardInfo.title,
                  desc: this.cardInfo.desc,
                  location: this.cardInfo.location,
                  cover_img: this.cardInfo.cover_img
                    ? this.$parseURL(this.cardInfo.cover_img).path
                    : '',
                  template_detail: JSON.stringify(template_detail),
                  head_url: this.cardInfo.head_url,
                  copy_url_switch: this.cardInfo.copy_url_switch,
                  copy_url_beginning: this.cardInfo.copy_url_beginning,
                  ...res.data
                });
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `保存数据失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      // 本地下载
      fileDownLoad(imgUrl, name) {
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          var bstr = atob(imgUrl.split(',')[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          var blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            `${this.liveDetail.subject || this.cardInfo.title || ''}邀约海报.png`
          );
        } else {
          // 这里就按照chrome等新版浏览器来处理
          const a = document.createElement('a');
          a.href = imgUrl;
          a.setAttribute(
            'download',
            `${this.liveDetail.subject || this.cardInfo.title || ''}邀约海报.png`
          );
          a.click();
        }
      },
      // 生成图片
      // 2024-0-09 由于预览效果使用了scale缩放，用offsetWidth/offsetHeight拿到的不是最终渲染的宽高，在mac上生成的邀请卡图片会出现文字错位问题。所以使用 getBoundingClientRect 方式获取宽高。
      drawCanvas(el) {
        if (!el) return;
        const rect = el.getBoundingClientRect();
        if (!rect || !rect.width || !rect.height) return;
        console.log('drawCanvas rect:', rect);
        html2canvas(el, {
          timeout: 500,
          allowTaint: true,
          useCORS: true,
          logging: false,
          width: Math.floor(rect.width),
          height: Math.floor(rect.height) - 1, //减掉1px的边框
          background: '#fff',
          scale: 2
        })
          .then(canvas => {
            const canvasImgUrl = canvas.toDataURL();
            this.fileDownLoad(canvasImgUrl);
          })
          .catch(err => {
            console.log(err);
          });
      },
      //确定防抖
      handleNickNameSwitch(value) {
        // this.cardInfo.nickNameSwitch = !value;
      },
      // 复制链接生成短链
      async genCopyUrlShortLink() {
        const params = {
          longUrl: this.inviteUrl.replace('invite_way=1', 'invite_way=4'),
          stripProtocol: 0
        };
        try {
          const result = await this.$fetch('getShortLinkUrl', params, {
            'Content-Type': 'application/x-www-form-urlencoded'
          });
          console.log('shortUrl result:', result);
          if (result?.code === 200 && result?.data?.shortUrl) {
            this.copyUrlShortLink = result.data.shortUrl;
          } else {
            console.error('生成短连接失败');
          }
        } catch (ex) {
          console.error('err shortUrl', ex);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .invitation-edit {
    .form-custom-code {
      color: #1e4edc;
      line-height: 26px;
      margin-top: 24px;
      cursor: pointer;
    }
  }

  .invitation-cardlist {
    &-item {
      width: 64px;
      margin-left: 8px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      height: 114px;
      border: 1px solid transparent;
      cursor: pointer;
      display: inline-block;
      transition: all 0.15s ease-in;

      &:nth-child(6n + 1) {
        margin-left: 0px;
      }
      &-disable {
        opacity: 0.4;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
      .img-tangle {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 20px;
        height: 20px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .model-content {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
        &-visible {
          display: block;
        }
      }
      .model-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 3px;
        top: 3px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        line-height: 16px;
        text-align: center;
        z-index: 1;
        i {
          color: #fff;
          font-size: 12px;
          &:hover {
            color: #fb2626;
          }
        }
      }
      .model-text {
        line-height: 14px;
        position: absolute;
        width: 100%;
        height: 22px;
        left: 0;
        bottom: 0;
        display: flex;
        background: rgba(0, 0, 0, 0.6);
        justify-content: space-around;
        align-items: center;
        color: #fff;
        span {
          display: inline-block;
          font-size: 12px;
          &:hover {
            color: #fb2626;
          }
        }
      }
      .model-bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
        border: 1px solid #f2f2f2;
        .model-content {
          display: block;
        }
        .model-content-visible {
          display: none;
        }
      }
      &__active {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
        border: 1px solid #fb3a32;
        &:hover {
          border: 1px solid #fb3a32;
        }
      }
    }
    &-first {
      background: #f7f7f7;
      i {
        width: 16px;
        position: absolute;
        left: 0;
        right: 0;
        top: 40px;
        margin: auto;
        font-size: 16px;
      }
    }
    &-tip {
      font-size: 12px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .invitation-rank-tip {
    margin-left: 8px;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.45);
  }
  .nickNamePreImg {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
</style>
<style lang="less">
  .inviteCardNicknamePreview {
    padding: 0;
    box-shadow: none;
    img {
      width: 240px;
      height: 504px;
    }
  }
  .copy-url-preview {
    padding: 0;
    box-shadow: none;
  }
  .invitation-edit {
    .upNicknameBox {
      display: flex;
      align-items: center;
      .vh-form-item__content {
        display: flex;
        align-items: center;
        margin-left: 0 !important;
        .vh-switch {
          margin-right: 10px;
        }
        .nickNameSwitchDesc {
          color: #8c8c8c;
          &.active {
            color: #595959 !important;
          }
        }
      }
    }
  }

  .part-std-item {
    .vh-form-item__content {
      .content-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;

        & .switch-label {
          color: #8c8c8c;
          vertical-align: middle;
        }
      }
      .content-body {
        display: flex;
        flex-direction: column;

        .preview-box {
          font-size: 14px;
          color: #8c8c8c;
          background: #f5f5f5;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400px;
          border-radius: 4px;
          margin-top: 6px;
          padding: 12px;
          display: inline-block;

          .preview-label {
            display: inline;
            margin-right: 6px;
            color: #0a7ff5;
          }
          .preview-content {
            display: inline;
            color: #262626;
          }
        }
      }
    }
  }

  // 表单行自动高度
  .part-std-item.auto-height {
    .vh-form-item__label,
    .vh-form-item__content {
      line-height: normal;

      .content-title {
        line-height: normal;

        .vh-switch {
          // swith组件在内容区
          padding-right: 6px;
        }
      }
    }

    // 表单行自定义标题
    .vh-form-item__label {
      display: inline-block;

      .label-title {
        vertical-align: middle;
      }
      .vh-switch {
        // swith组件在标题中
        margin-left: 12px;
      }
    }
  }
</style>
